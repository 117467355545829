<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" offset="0" md="10" offset-md="1">
                <h2 class="primary--text">Criar Utilizador</h2>
                <v-breadcrumbs
                    large
                    :items="crumbs"
                    divider="/"
                    class="pl-0"
                ></v-breadcrumbs>
                <v-divider class="mb-12"></v-divider>


                <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                    v-if="loading"
                ></v-progress-linear>

                <Form
                    ref="form"
                    submitText="Gravar"
                    @submited="createUser"
                    :loading="loading"
                >
                    <v-row>

                        <v-col cols="12" md="auto">
                            <v-switch
                                v-model="fields.is_external"
                                inset
                                color="success"
                                @change="changeExternal"
                                label="Utilizador Externo"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4">
                            <Input
                                v-model="fields.name"
                                type="name"
                                label="Nome *"
                                dense outlined
                                rules="required"
                                vid="name"
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <Input
                                v-model="fields.email"
                                type="email"
                                :label="fields.is_external ? 'Email' : 'Email *'"
                                dense outlined
                                :rules="fields.is_external ? 'email' : 'required|email'"
                                vid="email"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <Input
                                v-model="fields.telephone"
                                type="text"
                                dense outlined
                                label="Telefone"
                                vid="telephone"
                            />
                        </v-col>
                        <v-col cols="12" md="2">
                            <Input
                                v-model="fields.username"
                                type="name"
                                label="Username"
                                dense outlined
                                rules="|"
                                vid="Username"
                            />
                        </v-col>

                        <v-col cols="12" md="12" v-if="fields.is_external">
                            <v-autocomplete
                                dense outlined
                                :items="funcionarios_externos"
                                label="Funcionário Externo"
                                v-model="fields.funcionario"
                                item-value="Numero"
                                :item-text="item => item.Numero + ' - ' + item.Nome"
                            />
                        </v-col>
                        <v-col cols="12">
                            <MultipeSelect
                                v-model="fields.roles"
                                :items="roles"
                                item-text="display_name"
                                :disabled="fields.is_external"
                                dense outlined
                                item-value="id"
                                label="Roles"
                                rules="required"
                                vid="roles"
                                @change="roleChanged"
                            />
                        </v-col>

                        <v-col cols="12" md="4" v-if="fields.is_external == false">
                            <SearchUserById 
                                :funcionarios="funcionarios"  
                                dense outlined
                                v-model="fields.funcionario"
                                :selectedFunc="fields.funcionario"  
                                @input="lookForVehicles"
                                />
                        </v-col>

                        <v-col cols="12" md="12" v-if="fields.is_external == false">
                            <SelectAutoComplete
                                :personalizedFilter="true"
                                v-model="fields.funcionario"
                                :items="funcionarios"
                                return-object
                                dense outlined
                                item-text="Nome"
                                item-value="Codigo"
                                label="Funcionario *"
                                vid="funcionario"
                                @change="lookForVehicles"
                            />
                            <v-alert
                                v-if="usedCode"
                                outlined
                                type="warning"
                                prominent
                                border="left"
                            >
                                Avisamos que este utilizador está associado a outra conta
                            </v-alert>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-checkbox
                                v-model="onlyActivate"
                                :disabled="can_activate_sso"
                                label="Ativar login por SSO (só para domínios CONDURIL)"
                                dense outlined
                            />
                        </v-col>
                        <v-col cols="12" md="5" v-if="onlyActivate == false">
                            <v-checkbox
                                v-model="addPassword"
                                label="Colocar password (utilizador fica ativo)"
                                dense outlined
                            />
                        </v-col>
                        <v-col cols="12" md="7" v-if="addPassword">
                            <Input
                                label="Password"
                                type="password"
                                dense outlined
                                placeholder="Insira Password"
                                rules="small_letter|password_size|special_char_1|capital_letter|one_number"
                                vid="password"
                                v-model="fields.password"
                                hint="Mínimo 8 carateres"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-card>
                                <v-card-title class="primary white--text">
                                    Obras associadas
                                </v-card-title>
                                <v-card-text class="ma-0 pa-0">
                                        <v-simple-table>
                                            <template v-slot:default>
                                            <thead class="warning">
                                                <tr>
                                                <th class="text-left white--text">
                                                    Código
                                                </th>
                                                <th class="text-left white--text">
                                                    Descrição
                                                </th>
                                                <th class="text-left white--text">
                                                    Opções
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                v-for="item in obras_user"
                                                :key="item.Id"
                                                >
                                                <td>{{ item.Codigo }}</td>
                                                <td>{{ item.Descricao }}</td>
                                                <td>
                                                    <IconRemove
                                                        v-if="$store.getters.hasPermission(['super', 'works.user_accesses_manage'])"
                                                    />                                                    
                                                </td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-row>
                                            <v-col cols="12" md="10">
                                                <v-autocomplete
                                                    :items="obras"
                                                    label="Associar obra"
                                                    v-model="obra_selected"
                                                    dense outlined
                                                    item-value="Id"
                                                    :item-text="item=> item.Codigo + ' - ' + item.Descricao"
                                                />
                                            </v-col>
                                            <v-col cols="12" md="2">
                                                <v-btn
                                                    icon
                                                    :disabled="obra_selected == null"
                                                    @click="adicionarObra"
                                                    class="primary"
                                                >
                                                    <v-icon color="white">mdi-plus</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        

                        <v-col cols="12" v-if="fields.vehicles != null && isManobrador">
                            <v-col
                                cols="12"
                            >
                                <v-card style="width:100%;">
                                <v-subheader>
                                    Veículos Associados
                                </v-subheader>

                                <v-list>
                                    <template v-for="(item, index) in getVehiclesInfo">
                                    <v-list-item
                                        v-if="item.Descricao"
                                    >
                                        <v-list-item-action>
                                        <v-icon>mdi-truck</v-icon>
                                        </v-list-item-action>

                                        <v-list-item-content>
                                        <v-list-item-title>{{ item.Descricao }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-divider
                                        v-else-if="item.divider"
                                        :key="index"
                                    ></v-divider>
                                    </template>
                                </v-list>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                    
                </Form>

                <SuccessSnackbar message="Dados gravados!" v-model="success"/>

                <ErrorSnackbar v-model="error" :message="error_message"/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import MenuTabs from "@/components/UI/Tabs/MenuTabs";
    import IconRemove from '@/components/UI/IconRemove.vue';

    import Form from "@/components/UI/Forms/Form";

    import Input from "@/components/UI/Inputs/Input";

    import MultipeSelect from "@/components/UI/Inputs/MultipeSelect";

    import Select from "@/components/UI/Inputs/Select";

    import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";

    import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
    import SelectAutoComplete from "@/components/UI/Inputs/SelectAutoComplete";
    import SearchUserById from "@/components/UI/Inputs/SearchUserById"
    import { role } from "@/store/modules/role";
    import FuncionarioAPI from '@/api/Funcionario.js'
    import EntidadePessoalAPI from '@/api/EntidadePessoal.js'
    import Obra from '@/api/Obra.js'

export default {
    components: {
      MenuTabs,
      Form,
      Input,
      SuccessSnackbar,
      ErrorSnackbar,
      MultipeSelect,
      Select,
      SelectAutoComplete,
      SearchUserById,
      IconRemove
    },
    data: () => ({
        can_activate_sso: true,
        addPassword: false,
        onlyActivate: false,
        isManobrador: false,
        fields: {
            name: null,
            email: null,
            is_external: false, 
            telephone: null,
            roles: [],
            funcionario: null,
            vehicles: null,
            username: null,
            password: null,
            obras_user: []
        },
        loading: false,
        success: false,
        error: null,
        roles: [],
        obras: [],
        funcionarios: [],
        obra_selected: null,
        usedCode: false,
        used_codes: [],
        obras_user: [],
        funcionarios_externos: [],
        error_message: "",
    }),
    watch:{
        'fields.email':{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                if(val.includes('@conduril.pt'))
                    this.can_activate_sso = false
                else this.can_activate_sso = true
            }
        },
        'fields.is_external':{
            immediate: true,
            handler(val){
                if(val == null)
                    return

                if(val == false)
                    this.fields.roles = []
            }
        }
    },
    mounted: async function() {
        if(!this.$store.getters.hasPermission(["super", "users.create"])) {
            this.$router.push('/forbidden');
        }

        this.$store.dispatch("listRoles")
            .then(res => {
                this.roles = res.data;
            })

        FuncionarioAPI.allNoRestrictions()
            .then(res => {
                this.funcionarios = res.data;

            })

        FuncionarioAPI.allUsed().then((resp)    =>  {
            this.used_codes = resp.data
        })

        Obra.onlyWorks().then((resp)    =>  {
            this.obras = resp.data
        })

        EntidadePessoalAPI.all().then((resp)   =>  {
            this.funcionarios_externos = resp.data
        })
            
        this.loading = false
    },
    computed:{
        getVehiclesInfo(){
            return this.fields.vehicles
        }
    },
    methods: {
        changeExternal(event){
            if(event){
                this.roles.forEach(element => {
                    if(element.name == 'manobrador'){
                        this.fields.roles = []
                        this.fields.roles.push(element.id)
                    }                        
                });
            }
        },
        adicionarObra(){
            this.obras.forEach(element => {
                if(element.Id == this.obra_selected){
                    let ob = {
                        Id: element.Id,
                        Codigo: element.Codigo,
                        Descricao: element.Descricao
                    }

                    this.obras_user.push(ob)
                }                
            });
            this.obra_selected = null
        },
        async lookForVehicles(){
            if(this.fields.funcionario == null)
                return


            this.used_codes.forEach(code => {
                if(code == this.fields.funcionario.Codigo)
                    this.usedCode = true
            });
            
            let pessoalID = this.fields.funcionario.Codigo

            await this.$store.dispatch("listEquipamentosFromFuncionario", pessoalID)
                .then(res => {                    
                    this.fields.vehicles = res.data
                }) 
        },
        roleChanged(role_code){
            // Verificar o role
            // Caso o role indicado seja o de manobrador
            // a aplicação tem de pedir o veiculo associado ao manobrador

            let flagManobrador = false
            role_code.forEach(rc => {
                this.roles.forEach(role => {
                    if(rc == role.id)
                        if(role.name == "manobrador")
                            flagManobrador = true
                });
            });

            if(flagManobrador)
                this.isManobrador = true
            else
                this.isManobrador = false

            if(this.isManobrador)
                this.lookForVehicles
        },
        createUser: function() {

            this.loading = true;

            if(this.fields.funcionario == null){

                this.loading = false
                this.error_message = "Por favor selecione um funcionário para a conta ser válida"
                this.error = true
                return
            }
            
            if(this.fields.is_external == false)
                this.fields.funcionario = this.fields.funcionario.Codigo
            else{
                let my_roles = []
                this.roles.forEach(element => {
                    if(element.name == 'manobrador'){
                        my_roles.push(element.id)
                    }
                });
                
                this.fields.roles = my_roles
            }

            this.fields.obras_user = this.obras_user

            this.fields.let_active = this.onlyActivate

            this.$store.dispatch("createUser", this.fields)
                .then(res => {

                    this.success = true;

                    let self = this;

                    setTimeout(function() {
                        self.$router.push("/super/utilizadores");
                    }, 2000)

                    this.loading = false;
                })
                .catch(err => {

                    if(err.response.status == 422){

                        this.$refs.form.$refs.form.setErrors(err.response.data.errors);

                    }

                    this.error = true;

                    this.error_message = err.response.data.message;

                    this.loading = false;
                });
        },
    },
    computed: {
        crumbs: function() {
        return [
            {
            text: 'Criar Utilizador',
            disabled: true,
            to: '#',
            exact: true,
            },
        ];
        }
    }
}
</script>

<style scoped>

</style>
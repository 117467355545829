<template>
  <v-container fill-height fluid class="bg-image">
    
      <v-row justify="center" align="center">
        <v-dialog
          v-if="showMaintenance"
          v-model="showMaintenance"
          max-width="350px"
        >
          <v-container fluid class="ma-0 pa-0">
            <v-card class="elevation-0 ma-0 pa-0">
              <v-card-title class="primary white--text">
                Site em Manutenção
              </v-card-title>
              <v-card-text class="mt-2 text-center">
                O seu login não será bem sucedido enquanto o site estiver em manutenção. Agradecemos a sua compreensão!
              </v-card-texT>
              <v-card-text class="justify-center">
                <v-avatar height="105" width="300" tile style="margin: auto; display: block;">
                <v-img
                    src="@/assets/Maintenance/manutencao.png"
                    lazy-src="@/assets/Maintenance/manutencao.png"
                    contain
                >
                  </v-img>
                </v-avatar>
              </v-card-text>
              <v-card-text class="text-center">
                Tentaremos ser o mais breves possível!
              </v-card-text>
            </v-card>
          </v-container>
        </v-dialog>
        
          <v-col cols="12" md="4">
              <v-card
                :loading="loading"
                class="mx-auto my-12 align-self-center pa-5"
              >
                <template slot="progress">
                  <v-progress-linear
                    class="mb-8"
                    color="primary"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-row justify="center">
                  <v-avatar height="105" width="300" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/fundo_conduril.png"
                    >
                  </v-avatar>
                </v-row>

                <v-card-title class="primary--text">ODC - Login</v-card-title>

                <ValidationObserver ref="form" v-slot="{invalid}">
                  <v-form ref="login" :disabled="loading" @submit.prevent="submit" submitText="Login">
                <v-card-text>
                    <v-row
                      align="center"
                      class="mx-0"
                      no-gutters
                    >
                      <v-col cols="12">
                        <validation-provider name="Email" vid="email" rules="required" v-slot="{ errors }">
                          <v-text-field
                          class="text-white center-button" 
                          color="#111b2d"
                          v-model="fields.email" 
                          label="Email\Username" 
                          prepend-inner-icon="mdi-account" 
                          :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>

                      <v-col cols="12">
                        <validation-provider name="Password" vid="password" rules="required" v-slot="{ errors }">
                          <v-text-field
                          class="text-white center-button" 
                          v-model="fields.password" 
                          color="#111b2d"
                          type="password" 
                          label="Password" 
                          prepend-inner-icon="mdi-lock" 
                          :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>
                  <v-row justify="center" class="mt-8">
                    <v-btn
                      text
                      small
                        @click="reset_password.opened = !reset_password.opened"
                      >
                          Esqueci-me da Password
                    </v-btn>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row justify="center">
                    <v-col cols="12">
                      <v-btn 
                            style="display: block; margin: auto;" type="submit" color="primary" :disabled="invalid">
                        Login
                      </v-btn>
                    </v-col>
                    <v-col cols="12">
                      <v-btn                      
                        class="mt-4 mb-n4 font-weight-bold center-button"                      
                        rounded
                        block
                        style="background-color: #ffffff !important; opacity: 0.7;"
                        color="#111b2d"
                        @click="microsoftLogin"
                      >
                        <v-icon>mdi-microsoft</v-icon>
                        {{ $msal.isAuthenticated() ? user.userName : 'Usar conta Microsoft' }}
                      </v-btn>
                      <v-col 
                        cols="12"
                        class="mt-4">
                          <v-btn
                            rounded
                            depressed
                            small
                            style="display: block; margin: auto;"
                            @click="$msal.signOut()"
                            v-if="$msal.isAuthenticated()"
                          >
                            <v-icon>mdi-logout</v-icon>
                            Logout
                          </v-btn>
                        </v-col> 
                    </v-col>
                  </v-row>
                </v-card-actions>
                  </v-form>
              </ValidationObserver>

                <v-row justify="center" class="mt-5">
                  <div class="col-12 text-center pb-0"><p class="mb-0">Powered By</p></div>
                  <a target="_blank" href="https://www.dreamdealtech.pt/"><v-avatar height="45" width="140" tile class="mt-2 mb-2">
                    <img
                      src="@/assets/logo.png"
                    >
                  </v-avatar></a>
                </v-row>
              </v-card>
          </v-col>
      </v-row>

      <v-dialog
        v-model="reset_password.opened"
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="h5">Reset Password</span>
          </v-card-title>
          <v-card-text>
            <validation-observer v-if="!reset_password.reseted" ref="reset" v-slot="{ invalid }">
              <v-form @submit.prevent="reset">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <validation-provider v-slot="{ errors }" vid="email" name="Email" rules="required|email">
                        <v-text-field
                          v-model="reset_password.email"
                          prepend-inner-icon="mdi-at"
                          label="Email"
                          type="email"
                          clearable
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-col align="center" justify="space-around">
                      <v-btn :disabled="invalid" depressed color="primary" class="mr-5 ml-5 mb-5" type="submit">
                        Enviar email
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </validation-observer>
            <v-container v-else>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                    offset-md="3"
                  >
                  <h2 class="success--text text-center">Email enviado!</h2>
                  <v-row class="mt-6" align="center" justify="space-around">
                    <v-icon
                      large
                      class="text-center"
                      color="success"
                    >
                      mdi-checkbox-marked-circle-outline
                    </v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="reset_password.opened = false"
            >
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ErrorSnackbar v-model="error" :message="error_message"/>
  </v-container>
</template>
<script>
import Input from "@/components/UI/Inputs/Input";
import Form from "@/components/UI/Forms/Form";
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import Button from "@/components/UI/Buttons/Button";
import { msalMixin } from 'vue-msal'; 
import User from '@/api/User.js'
import System from '@/api/System.js'
import axios from "axios";

export default {
  components: {
    Input,
    Form,
    ErrorSnackbar,
    Button
  },
  mixins: [msalMixin],
  data: () => ({
    showMaintenance: false,
    reset_password: {
      opened: false,
      email: "",
      reseted: false,
    },
    loading: false,
    fields: {
      email: null,
      password: null,
    },
    error: false,
    error_message: "",
    system_status: null
  }),
  mounted() {
    if(this.$store.state.user.id != undefined)
      this.$store.dispatch("logout");

    System.status().then((resp) =>  {
      this.system_status = resp.data

      if(this.system_status.maintenance.value == "0")
        this.showMaintenance = false
      else this.showMaintenance = true
    })
  },
  methods: {
    microsoftLogin(){
      if(this.$msal.isAuthenticated()){
        User.microsoftLogin(this.$msal.data.user.userName, this.$msal.data.user.idToken.oid)
        .then(res => {
            
            if(res.data.two_factor){

              this.$router.push("/mfa");

              return;
            }

            this.$router.push('/dashboard');
          }).catch(err => {
            
            if(err.response.status == 422) {

              this.$refs.login.setErrors(err.response.data.errors);
              
              return;
            }
        });
      }else{
        this.$msal.signIn()
      }
    },
    reset () {
      this.$refs.reset.validate().then((valid) => {
        this.$store.dispatch("resetUser", this.reset_password.email)
          .then(() => {
            
            this.reset_password.reseted = true;
          })
          .catch(err => {

            if(err.response.status == 422) {

                this.$refs.reset.setErrors(err.response.data.errors);
                
                return;
              }

              this.reset_password.reseted = false;

              this.error = true;

              this.error_message = err.response.data.message;

              this.loading = false;
          });
      });
    },
    login(){
      this.$store.dispatch("login", this.fields)
          .then(res => {
            
            if(res.data.two_factor){

              this.$router.push("/mfa");

              return;
            }

            this.$router.push('/dashboard');
          })
          .catch(err => {

            if(err.response.status == 422){

              this.$refs.login.$refs.form.setErrors(err.response.data.errors);

            }

            this.error = true;

            this.error_message = err.response.data.message;

            this.loading = false;
          });
    },
    submit: async function() {
      this.loading = true;

      await this.$store.dispatch("logout", this.fields)
          .then(() => {
            this.login()
          })
          .catch(() => {
            this.login()
          });
    }
  },
  computed:{
    user() {
        let user = null;
        if (this.msal.isAuthenticated) { // Note that the dollar sign ($) is missing from this.msal
          user = {
            ...this.msal.user,
            profile: {}
          }
          if (this.msal.graph && this.msal.graph.profile) {
              user.profile = this.msal.graph.profile
          }
        }
        
        return user;
      }
  }
}
</script>
<style scoped>
.bg-image {
    background-image: url("@/assets/fundo.jpg");
    background-position: center;
    background-size: cover;
  }
</style>
<template>
    <v-container fluid>
        <MiniAlerts style="white-space: pre-line" v-if="alert.showAlert" :message="alert.message" />
        <validation-observer ref="form" v-slot="{ invalid }">
            <v-form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12" md="2">
                        <v-menu
                            v-if="method == 'create'"
                            v-model="menu0"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                class="d-none d-lg-block"
                                v-model="fields.data"
                                label="Insira Data"
                                outlined dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            <v-text-field 
                                class="d-block d-lg-none" 
                                v-model="fields.data" 
                                type="date" 
                                id="birthday" 
                                label="Insira data"
                                dense outlined
                                :max="new Date(new Date()+1).toISOString().substr(0, 10)"
                            />
                            
                            </v-date-picker>
                            </template>
                            <v-date-picker
                                :allowed-dates="(date) => date <= new Date(new Date()+1).toISOString().substr(0, 10)"
                                v-model="fields.data"
                                @input="menu0 = false"
                                @change="dataChanged"
                            ></v-date-picker>
                        </v-menu>
                        <v-text-field
                            v-else
                            dense outlined
                            v-model="fields.data"
                            disabled
                            label="Data"
                        />
                    </v-col>
                    <v-col cols="12" md="4" v-if="fields.data != null">
                        <validation-provider v-slot="{ errors }" vid="Obras" name="Obras" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.obra_id"
                                :items="obras"
                                outlined dense
                                :item-text="item => item.Codigo + ' - ' + item.Descricao"
                                item-value="Id"
                                :error-messages="errors"
                                label="Obras *"
                                @change="changeWork"
                            />
                        </validation-provider>
                    </v-col>
                    <v-col cols="12" md="3" v-if="fields.obra_id != null && frentes_obra.length > 0 && hideFrentes == false">
                        <validation-provider v-slot="{ errors }" vid="Frentes" name="Frentes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.frente_id"
                                :items="frentes_obra"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Frentes de Obra *"
                                @change="changeFrenteObra"
                            />
                        </validation-provider>
                    </v-col>
                    <!--<v-col cols="12" md="3" v-if="classeNeeded">
                        <validation-provider v-slot="{ errors }" vid="Classes" name="Classes" rules="required">
                            <v-autocomplete
                                :disabled="method == 'update'"
                                v-model="fields.classe_id"
                                :items="classes"
                                outlined dense
                                :item-text="item => item.Descricao"
                                item-value="ID"
                                :error-messages="errors"
                                label="Classe *"
                                @change="changeClasse"
                            />
                        </validation-provider>
                    </v-col>-->
                </v-row>
                <v-row v-if="showContent">
                    <v-col cols="12">
                        <v-simple-table class="d-none d-lg-block">
                            <template v-slot:default>
                                <thead class="primary">
                                    <tr>
                                        <th width="35%" class="text-center white--text text-caption font-weight-bold">
                                            Nome UO
                                        </th>
                                        <th width="15%" class="text-center white--text text-caption font-weight-bold">
                                            Natureza
                                        </th>
                                        <th width="15%" class="text-center white--text text-caption font-weight-bold">
                                            Produção
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Notas
                                        </th>
                                        <th class="text-center white--text text-caption font-weight-bold">
                                            Opções
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(item, index) in getLines" :key="index"
                                    >
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Classe" name="Classe" rules="required">
                                                <v-autocomplete
                                                    v-if="mqt!=null"
                                                    class="mt-4"
                                                    dense outlined
                                                    v-model="item.classe_id"
                                                    :items="classes"
                                                    :error-messages="errors"
                                                    clearable
                                                    :item-text="item => item.Descricao"
                                                    item-value="ID"
                                                    label="Classe *"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Natureza" name="Natureza" rules="required">
                                                <v-autocomplete
                                                    v-model="item.natureza_id"
                                                    :error-messages="errors"
                                                    :items="natureza_trabalhos"
                                                    :item-text="item=>item.code + ' - ' + item.natureza + ' (' + item.unidade + ')'"
                                                    dense outlined
                                                    item-value="id"
                                                    label="Natureza *"
                                                    class="mt-4"
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Produção" name="Produção" rules="required">
                                                <v-text-field
                                                    label="Produção *"
                                                    class="mt-4"
                                                    dense outlined
                                                    :error-messages="errors"
                                                    clearable
                                                    v-model="item.producao"
                                                    type="number"
                                                />
                                            </validation-provider>
                                        </td>                                     
                                        <td>
                                            <validation-provider v-slot="{ errors }" vid="Local" name="Local" rules="|">
                                                <v-text-field
                                                    class="mt-4"
                                                    dense outlined
                                                    label="Local"
                                                    placeholder="Insira local"
                                                    v-model="item.notas"
                                                    @click="openEditorModal(item)"   
                                                />
                                            </validation-provider>
                                        </td>
                                        <td>
                                            <v-btn
                                                dark
                                                x-small
                                                fab
                                                color="error"
                                                @click="deleteLine(item.id)"
                                                >
                                                <v-icon x-small>mdi-delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>

                        <v-data-iterator
                            :items="getLines"
                            item-key="id"
                            :items-per-page="-1"
                            hide-default-footer
                            class="d-block d-lg-none ma-0 pa-0"
                        >
                            <template v-slot:default="{ items }">
                                <v-row class="ma-0 pa-0">
                                    <v-col
                                        class="ma-0 pa-0"
                                        v-for="(item, index) in items"
                                        :key="index+200000"
                                        cols="12"
                                    >
                                        <v-card elevation="0" class="mt-2 rounded-lg ma-0 pa-0" outlined>
                                            <v-list
                                                dense
                                            >
                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <validation-provider v-slot="{ errors }" vid="Unidade de Obra" name="Unidade de Obra" rules="required">
                                                            <v-autocomplete
                                                                v-if="mqt!=null"
                                                                class="mt-4"
                                                                dense outlined
                                                                v-model="item.classe_id"
                                                                :items="classes"
                                                                :error-messages="errors"
                                                                clearable
                                                                :item-text="item => item.Descricao"
                                                                item-value="ID"
                                                                label="Classe *"
                                                            />
                                                        </validation-provider>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <validation-provider v-slot="{ errors }" vid="Produção" name="Produção" rules="required">
                                                            <v-text-field
                                                                label="Produção *"
                                                                class="mt-4"
                                                                dense outlined
                                                                :error-messages="errors"
                                                                clearable
                                                                v-model="item.producao"
                                                                type="number"
                                                            />
                                                        </validation-provider>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <validation-provider v-slot="{ errors }" vid="Local" name="Local" rules="|">
                                                            <v-text-field
                                                                class="mt-4"
                                                                dense outlined
                                                                label="Local"
                                                                placeholder="Insira local"
                                                                v-model="item.notas"
                                                                @click="openEditorModal(item)"   
                                                            />
                                                        </validation-provider>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content class="align-center">
                                                        <v-btn
                                                            dark
                                                            x-small
                                                            fab
                                                            color="error"
                                                            @click="deleteLine(item.id)"
                                                            >
                                                            <v-icon x-small>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </v-list-item-content>
                                                </v-list-item>

                                            </v-list>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-data-iterator>
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn fab @click="addLineToTable" class="primary">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>  
                <v-row v-if="showContent && inside_diary == false" class="mt-4">
                    <v-col cols="12" align="end">
                        <v-btn :disabled="invalid" type="submit" class="success" rounded>
                            Gravar<v-icon class="ml-2">mdi-content-save</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>          
            </v-form>
        </validation-observer>

        <v-dialog
            v-if="editorModal"
            v-model="editorModal"
            max-width="600px"
            scrollable
        >
            <InputEditorModal @change-value="changeEditorModal" vid="editor_local" :title="'Local'" :label="'Local'" v-model="inputEditorModalData.itemModel"/>
        </v-dialog>

        <DialogSuccess :opened="success" @on-ok="onSuccess" >
            Diária gravada com sucesso
        </DialogSuccess>

    </v-container>
</template>
<script>
import DateInput from "@/components/UI/Inputs/DateInput"
import InputEditorModal from "@/components/UI/Modals/InputEditorModal";
import Obra from "@/api/Obra.js"
import FrenteObra from "@/api/FrenteObra.js"
import UnidadesObra from "@/api/UnidadesObra.js"
import DiaryKPI from "@/api/DiaryKPI.js"
import Classe from "@/api/Classe.js"
import dayjs from 'dayjs'
import ErrorSnackbar from "@/components/UI/Snackbars/ErrorSnackbar";
import ConfirmModal from '@/components/Modals/ConfirmModal.vue';
import SuccessSnackbar from "@/components/UI/Snackbars/SuccessSnackbar";
import Loader from "@/components/UI/Loaders/Loader";
import MiniAlerts from '@/components/Alerts/MiniAlerts.vue';
import Tooltip from '@/components/UI/Tooltip.vue';
import DialogSuccess from '@/components/UI/DialogSuccess.vue';
import NaturezaTrabalhos from '@/api/NaturezaTrabalhos.js'

export default{
    name:"DiaryKPI",
    components:{
        DateInput,
        DialogSuccess,
        InputEditorModal,
        ErrorSnackbar,
        ConfirmModal,
        SuccessSnackbar,
        Loader,
        MiniAlerts,
        Tooltip
    },
    props:{
        method: String,
        diary: Object,
        inside_diary: {
            typeof: Boolean,
            default: false
        },
        fields_diary:{
            typeof: Object,
            default: null
        }
    },
    watch: { 
        diary: {
            immediate: true, 
            handler (val) {
                if(!val) {
                    return;
                }
        
                let data = {...val};
        
                this.fields = data;

                this.line = this.fields.lines
                this.fields.frente_id = data.obra_frente_id

                this.loadingContent = 0

                this.changeWork()

                if(this.fields.frente_id == null)
                    this.hideFrentes = true

                
                this.classesPorObra()

                this.showContent = true
            }
        },
    },
    data(){
        return{
            editorModal: false,
            menu0: false,
            menu1: false,
            menu2: false,
            classeNeeded: false,
            natureza_trabalhos: [],
            confirmationModal: {
                show: false,
                title: '',
                message: ''
            },
            alert:{
                showAlert: false,
                message: ''
            },
            loadingContent: 2,
            hideFrentes: false,
            error: false,
            timeout: 2000,
            error_message: "",
            showContent: false,
            editorModal:false,
            success: false,
            loader: false,
            mqt: null,
            inputEditorModalData:{
                itemModel:null,
                id: null,
            },  
            fields:{
                obra_id: null,
                frente_id: null,
                classe_id: null,
                data: null,
                belongs_to_diary: null,
                diary_creator: null
            },
            deletedLines: [],
            line: [
                {
                    id: dayjs().unix() + '-new',
                    classe_id: null,
                    producao: null,
                    notas: null
                }
            ],
            obras: [],
            classes:[],
            frentes_obra: []
        }
    },
    mounted(){
        this.fillBaseData()
    },
    methods:{  
        async fillBaseData(){
            let data = {
                tipo: 'O'
            }
            
            await Obra.all(data).then((resp)   =>  {
                this.obras = resp.data;
            });

            NaturezaTrabalhos.all().then((resp) =>  {
                this.natureza_trabalhos = resp.data
            })

            
        
            if(this.inside_diary){
                this.fields.data = this.fields_diary.Data
                this.fields.obra_id = this.fields_diary.obra_id
                this.dataChanged()
                this.changeWork()
                this.fields.belongs_to_diary = this.fields_diary.id
            }
        },  
        deleteLine(){
            if(this.line.length == 1){
                this.error_message = "Não pode apagar a única linha que tem"
                this.error = true
                return
            }
                
            let index = this.line.findIndex(item => item.id == id);

            this.line.splice(index, 1);

            if(!id.includes('new'))
                this.deletedLines.push(id)
        },
        onSuccess(){
            if(this.method == 'update'){
                window.location.reload()
                return
            }

            this.success = false;
            this.$router.push('/kpi/lista-de-diarios')
        },
        async submit(diary_hash = null, diary_creator = null){
            this.fields.lines = this.line
            this.fields.deletedLines = this.deletedLines

            if(diary_hash != null){
                this.fields.belongs_to_diary = diary_hash
                this.fields.diary_creator = diary_creator
            }

            console.log("EU VOU")
            console.log(this.method)
            console.log(this.fields)
            
            await DiaryKPI[this.method](this.fields, this.fields.id).then((resp)  =>  {                
                this.success = true
            })            
        },
        changeEditorModal(e){
            this.line.forEach(element => {
                if(element.id == this.inputEditorModalData.id)
                    element.notas = e
            });
        },   
        openEditorModal(item){
            this.inputEditorModalData.itemModel = item.local
            this.inputEditorModalData.id = item.id
            this.editorModal=true
        },
        dataChanged(){
            if(this.obras.length == 1){
                this.fields.obra_id = this.obras[0].Id
                this.changeWork()
            }
        },
        deleteLine(id){
            if(this.line.length == 1){
                this.error_message = "Não pode apagar a única linha que tem"
                this.error = true
                return
            }
                
            let index = this.line.findIndex(item => item.id == id);

            this.line.splice(index, 1);

            if(!id.includes('new'))
                this.deletedLines.push(id)
        },
        changeFrenteObra(){
            if(this.fields.obra_id == null)
                return

            if(this.fields.frente_id == null)
                return

            let frente = null
            this.frentes_obra.forEach(element => {
                if(element.ID == this.fields.frente_id)
                    frente = element
            });

            if(frente != null){
                if(frente.classes.length > 0){
                    this.classes = frente.classes
                    this.classeNeeded = true
                }else 
                    this.showContent = true
            }

            this.showContent = true
            
        },
        addLineToTable(){
            this.line.push({
                id: dayjs().unix() + '-new',
                classe_id: null,
                natureza_id: null,
                producao: null,
                notas: null
            })
        }, 
        changeWork(){
            let ObraPai = null
            // Vou obter o ObraPai que é o código da obra, para obter a sua frente
            // Códigos exemplo: 0110203 é da obra MOLHE SINES 3ª F
            // Vamos à tabela ObraFrente ver quais as obras que têm esse código
            this.obras.forEach(element => {
                if(element.Id == this.fields.obra_id)
                    ObraPai = element.Codigo
            });

            FrenteObra.all(ObraPai).then((resp) =>  {
                this.frentes_obra = resp.data

                if(this.frentes_obra.length == 0){
                    this.classeNeeded = true  
                    this.classesPorObra()
                }else{
                    if(this.frentes_obra.length == 1){
                        this.fields.frente_id = this.frentes_obra[0].ID

                        this.changeFrenteObra()
                    }
                }
            })
            
            let data = {
                get_object: true
            }
            UnidadesObra.findByWork(this.fields.obra_id, data).then((resp)    =>  {
                this.mqt = resp.data
                console.log(this.mqt)
            })
        },
        classesPorObra(){
            // Também temos de verificar se esta obra tem classes
            Classe.getClassesByObraPai(this.fields.obra_id).then((resp) =>  {
                this.classes = resp.data
                
                if(this.classes == 0){
                    this.showContent = true
                    return
                }
                if(this.classes.length == 1){
                    this.fields.classe_id = this.classes[0].ID

                    this.changeClasse()
                }

                if(this.classes.length > 0){
                    this.showContent = true
                }
            })
        },
        changeClasse(){
            if(this.fields.obra_id == null)
                return
            
            if(this.fields.classe_id == null)
                return

            this.showContent = true
        }
    },
    computed:{
        getLines(){
            return this.line
        }
    }
}
</script>
<style>
.v-text-field__slot label,input{
    font-size: 13px !important;
}

.v-list-item__title{
    font-size: 13px !important;
}

.v-select__slot label,input{
    font-size: 13px !important;
}
</style>